<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Name"
              filled
              v-model="region.descript"
              dense
              outlined
              readonly
              :error="falseValue"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Type" filled v-model="region.regionType" dense outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field label="Description" filled v-model="region.desc" dense outlined readonly></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8">
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                Total Outlets
                <v-chip class="ma-2" small v-if="region.outlets">{{region.outlets.length}}</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text>
                Total Sub Regions
                <v-chip class="ma-2" small v-if="region.subregions">{{region.subregions.length}}</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card tile>
              <v-card-text>
                Total Sale Reps
                <v-chip class="ma-2" small>0</v-chip>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- map -->
        <map-section></map-section>
        <!-- map -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapSection from "../_components/map.vue";
export default {
  components: {
    MapSection
  },
  data() {
    return {
      falseValue: false,
      region: {},
      outlet: {}
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territory/${val}`)
        .then(res => {
          self.region = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {}
};
</script>

<style>
</style>