<template>
  <div>
    <GmapMap ref="mapRef" :center="cordinates" :zoom="17" style="height: 360px">
      <GmapMarker :position="cordinates" :clickable="true"></GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cordinates: { lat: -1.219719, lng: 36.889224 }
    };
  }
};
</script>

<style lang="scss" scoped>
</style>